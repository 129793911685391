import React from 'react';

const PianoIcon = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 240.36 230.61"
      className='activity__icon-piano'
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: var(--dark-color);
            }

            .cls-2 {
                fill: var(--dark-color);
            }

            .cls-3 {
                fill: var(--dark-color);
            }
          `}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            d="m240.15,198.87c-.23,2.02-1.15,10.5-1.99,15.71-.46,2.86-.95,5.95-2.38,8.38-3.17,5.4-8.27,7.57-14.58,7.57-68.42-.06-136.84-.04-205.25-.04-1.33,0-2.69.25-3.98.02-1.86-.33-3.86-.65-5.45-1.57C1.79,226.24,0,222.01,0,216.52c.09-53.55.07-107.1.03-160.66,0-4.95.47-9.74,1.85-14.54,3.87-13.48,10.94-24.57,22.84-32.33C33.59,3.22,43.5.9,53.84.2c8.12-.55,16.25.03,24.17,1.89,13.67,3.22,24.34,11.05,32.41,22.44,7.29,10.3,12.3,21.8,16.9,33.47,5.52,14.02,11.52,27.82,20.4,40.06,3.52,4.84,7.61,9.45,12.15,13.33,7.04,6.02,16.12,7.4,24.9,8.96,6.47,1.15,13.06,1.56,19.58,2.44,7.25,.98,13.45,4.24,18.81,9.13,4.84,4.41,8.28,9.8,10.72,15.87,2.76,6.86,4.58,13.94,5.32,21.31,.23,2.28,.75,7.93,.83,13.2s.62,8.68,.12,16.58Zm-120.69,6.58v-33.71h-10.98v34.06h-13.37v-34.07h-10.88v34.07h-13.32c0-11.34,0-22.52,0-33.71-.13-.13-.25-.26-.38-.39h-10.44v34.12h-13.37v-34.12c-3.62,0-7.04,0-10.47,0-.12.13-.25.27-.37.4v33.69h-13.37c0-11.34,0-22.51,0-33.69-.12-.13-.25-.27-.37-.4h-11.78v46.7h213.66v-46.69c-6.17,0-12.19,0-18.21,0-.13.13-.25.26-.38.39v33.69h-13.41v-34.02h-10.95v34.01h-13.39v-34.01h-10.82v33.69c-.13.14-.25.28-.38.41h-12.97v-34.12h-10.86v34.12h-12.81c-.13-.14-.25-.28-.38-.41Z"
            className="cls-2"
          />
          <path className="cls-2" d="m22.5,172.11c-.12-.13-.25-.27-.37-.4.12.13.25.27.37.4Z" />
          <path className="cls-2" d="m36.24,171.7c-.12.13-.25.27-.37.4.12-.13.25-.27.37-.4Z" />
          <path className="cls-3" d="m70.9,172.1c-.13-.13-.25-.26-.38-.39.13.13.25.26.38.39Z" />
          <path className="cls-3" d="m205.8,171.7c-.13.13-.25.26-.38.39.13-.13.25-.26.38-.39Z" />
          <path className="cls-1" d="m119.84,205.87c-.13-.14-.25-.28-.38-.41.13.14.25.28.38.41Z" />
          <path className="cls-1" d="m156.86,205.45c-.13.14-.25.28-.38.41.13-.14.25-.28.38-.41Z" />
        </g>
      </g>
    </svg>
  );
};

export default PianoIcon;
