import React from 'react';

const WebDevIcon = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 240.36 230.61"
      className='activity__icon-webDev'
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: var(--dark-color);
            }

            .cls-2 {
                fill: var(--dark-color);
            }

            .cls-3 {
                fill: var(--dark-color);
            }
          `}
        </style>
      </defs>
      <g id="Capa_1" data-name="Capa 1">
        <g>
          <path className="cls-2" d="m154.46,183.66c-1.19-.72-2.22-1.69-3.05-2.87-.38-.54-.7-1.11-.97-1.71H15.44V46.02h183.25v86.89c3.91.35,7.19,2.92,8.57,6.44,1.22-.67,2.58-1.08,4.01-1.21.97-.08,1.93-.02,2.86.16V12.87c0-7.1-5.77-12.87-12.87-12.87H12.87C5.77,0,0,5.77,0,12.87v168.8c0,7.1,5.77,12.87,12.87,12.87h140.42c-1.1-2.22-1.36-4.72-.71-7.13.37-1.39,1.02-2.65,1.88-3.74Zm30.1-162.05c0-2.62,2.13-4.75,4.75-4.75h4.75c2.62,0,4.75,2.13,4.75,4.75v5.39c0,2.62-2.13,4.75-4.75,4.75h-4.75c-2.62,0-4.75-2.13-4.75-4.75v-5.39Zm-22.09,0c0-2.62,2.13-4.75,4.75-4.75h4.75c2.62,0,4.75,2.13,4.75,4.75v5.39c0,2.62-2.13,4.75-4.75,4.75h-4.75c-2.62,0-4.75-2.13-4.75-4.75v-5.39Zm-22.09,0c0-2.62,2.13-4.75,4.75-4.75h4.75c2.62,0,4.75,2.13,4.75,4.75v5.39c0,2.62-2.13,4.75-4.75,4.75h-4.75c-2.62,0-4.75-2.13-4.75-4.75v-5.39Z"/>
          <path className="cls-2" d="m229.26,174.6l-1.43-8.1c-.19-1.09-1.23-1.82-2.32-1.63l-7.2,1.27c-.86-2.03-1.97-3.93-3.28-5.67l4.7-5.61c.34-.41.51-.93.46-1.46-.05-.53-.3-1.02-.71-1.36l-6.3-5.29c-.41-.34-.94-.51-1.46-.46-.53.05-1.02.3-1.36.71l-4.7,5.6c-1.93-.98-3.99-1.74-6.15-2.24v-7.31c0-1.11-.9-2.01-2.01-2.01h-8.22c-1.11,0-2.01.9-2.01,2.01v7.31c-2.16.5-4.22,1.26-6.15,2.24l-4.7-5.6c-.71-.85-1.98-.96-2.83-.25l-6.3,5.28c-.41.34-.66.83-.71,1.36-.05.53.12,1.06.46,1.46l4.7,5.61c-1.31,1.74-2.41,3.64-3.28,5.67l-7.2-1.27c-1.09-.19-2.13.54-2.32,1.63l-1.43,8.1c-.09.52.03,1.06.33,1.5.31.44.77.73,1.29.83l7.21,1.27c.12,2.23.51,4.39,1.13,6.45l-6.33,3.66c-.46.26-.8.7-.93,1.22-.14.51-.06,1.06.2,1.52l4.11,7.12c.26.46.7.8,1.22.93.17.05.35.07.52.07.35,0,.69-.09,1-.27l6.34-3.66c1.49,1.6,3.17,3.01,5.01,4.21l-2.5,6.88c-.18.5-.16,1.05.07,1.53s.63.86,1.13,1.04l7.72,2.81c.22.08.45.12.69.12.29,0,.58-.06.85-.19.48-.22.85-.63,1.04-1.13l2.5-6.88c1.07.13,2.16.2,3.27.2s2.2-.07,3.27-.2l2.5,6.88c.18.5.55.91,1.04,1.13.27.13.56.19.85.19.23,0,.46-.04.69-.12l7.73-2.81c.5-.18.91-.56,1.13-1.04.22-.48.25-1.03.07-1.53l-2.5-6.87c1.84-1.2,3.52-2.61,5.01-4.21l6.34,3.66c.31.18.65.27,1,.27.17,0,.35-.02.52-.07.51-.14.95-.47,1.22-.93l4.11-7.12c.27-.46.34-1.01.2-1.52-.14-.51-.47-.95-.93-1.22l-6.33-3.65c.63-2.06,1.01-4.22,1.13-6.45l7.21-1.27c.52-.09.99-.39,1.29-.83.32-.44.44-.98.34-1.5Zm-35.87,12.87c-6.09,0-11.03-4.94-11.03-11.03s4.94-11.03,11.03-11.03,11.03,4.94,11.03,11.03-4.94,11.03-11.03,11.03Z"/>
          <g>
            <path className="cls-2" d="m89.92,119.78c0-2.59-1.52-4.98-3.87-6.07l-21.54-10.01,21.54-10.01c2.35-1.09,3.87-3.48,3.87-6.07v-.17c0-2.3-1.16-4.41-3.09-5.64-1.07-.68-2.32-1.05-3.61-1.05-.97,0-1.95.21-2.82.62l-34.44,16.02c-2.35,1.09-3.87,3.48-3.87,6.07v.48c0,2.59,1.52,4.97,3.87,6.07l34.44,16.02c.89.41,1.84.62,2.82.62,1.28,0,2.52-.36,3.6-1.05,1.94-1.24,3.09-3.35,3.09-5.64v-.18h0Z"/>
            <path className="cls-2" d="m123.91,64.44c-1.26-1.72-3.28-2.75-5.41-2.75h-.17c-2.92,0-5.49,1.87-6.38,4.65l-22.69,70.31c-.65,2.03-.28,4.27.97,5.99,1.26,1.72,3.28,2.75,5.41,2.75h.17c2.92,0,5.48-1.87,6.37-4.65l22.69-70.31c.65-2.03.29-4.27-.97-5.99Z"/>
            <path className="cls-2" d="m168.17,97.39l-34.44-16.02c-.87-.4-1.84-.62-2.81-.62-1.29,0-2.54.36-3.61,1.04-1.94,1.24-3.1,3.35-3.1,5.64v.17c0,2.59,1.52,4.97,3.88,6.07l21.54,10.01-21.54,10.01c-2.35,1.09-3.87,3.48-3.87,6.07v.18c0,2.3,1.16,4.4,3.1,5.64,1.08.69,2.32,1.05,3.6,1.05.98,0,1.93-.21,2.82-.62l34.44-16.02c2.35-1.1,3.87-3.48,3.87-6.07v-.48c0-2.59-1.52-4.98-3.87-6.07Z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WebDevIcon;
